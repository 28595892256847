import { BasicTenantEntity, DictionaryTableNames } from '@/service/model/types';
import { SubOrderEntity } from '@/service/client/Order.type';

export interface PrintDataVo {
  dics: Record<DictionaryTableNames, any[]>;
  uniques: Array<{
    id: number;
    cache_key: string;
    code: string;
    code2: string;
    begin: number;
  }>;
  skus: SubOrderEntity[];
  form_data: Record<string, string>;
  expand_print: number;
  temp_desc: string;
  temp_nodes: Record<string, any>[];
  order_type: number;
  pagination: {
    page_size: number;
  };
}

export interface PrintTaskEntity extends BasicTenantEntity {
  name: string;
  progress: number;
  total: number;
  order_id: string;
  reject_reason: string;
  download_url: string;
  duration: number;
  direction: number;
  rid: string;
  print_data?: string;
}

export interface CreatePrintTaskDto {
  name: string;
  order_id: string;
  direction: number;
}

export enum PrintTaskStatusEnum {
  NEW = 1,
  RUNNING = 2,
  ZIPPING = 3,
  CDN = 4,
  DONE = 5,
  CANCEL = 6,
  FAILURE = 7,
  ARCHIVED = 8,
  ALL = 999,
  OTHER = 888,
}

export const PrintTaskStatusMap = {
  [PrintTaskStatusEnum.NEW]: '新建',
  [PrintTaskStatusEnum.RUNNING]: '执行中',
  [PrintTaskStatusEnum.ZIPPING]: '压缩中',
  [PrintTaskStatusEnum.CDN]: '上传CDN中',
  [PrintTaskStatusEnum.DONE]: '完成',
  [PrintTaskStatusEnum.CANCEL]: '已取消',
  [PrintTaskStatusEnum.FAILURE]: '执行失败',
  [PrintTaskStatusEnum.ARCHIVED]: '已归档',
};

export interface SearchPrintTaskListReq {
  status?: PrintTaskStatusEnum;
  order_id?: string;
  name?: string;
  count?: number;
  page?: number;
}
